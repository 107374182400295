












































































import { Component, Inject, Vue } from "vue-property-decorator";
import FileDrop from "@/components/common/filedrop.vue";
import FileSelection from "@/components/common/FileSelection.vue";
import TasksList from "@/components/tasks/TasksList.vue";
import { BackendClient, FileDescriptor } from "ibf-backend";

@Component({
  components: {
    FileSelection,
    FileDrop,
    TasksList,
  },
})
export default class RppToPSA extends Vue {
  @Inject("backendClient") client!: BackendClient;

  private selectedUserFiles: FileDescriptor[] = [];

  private selectedComputerFiles: File[] = [];

  private selectedComputerFilesChange(files: File[]) {
    this.selectedComputerFiles = files;
  }

  private launchConversion() {
    this.selectedUserFiles.forEach((fileDescriptor) =>
      this.client.taskApi.createTask({
        type: "RPPtoPSA",
        parameters: { file: fileDescriptor.name },
        files: [
          {
            name: fileDescriptor.name,
            context: "USER",
            path: fileDescriptor.uri,
          },
        ],
      })
    );

    this.selectedComputerFiles.forEach((file) =>
      this.client.taskApi.createTask(
        {
          type: "RPPtoPSA",
          parameters: { file: file.name },
          files: [
            {
              name: file.name,
              context: "TASK",
            },
          ],
        },
        [file]
      )
    );

    this.selectedComputerFiles = [];
    this.selectedUserFiles = [];
  }

  private get filesToConvertCount(): number {
    return this.selectedUserFiles.length + this.selectedComputerFiles.length;
  }
}
