



















import Vue from "vue";
import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import { warn } from "vue-class-component/lib/util";

@Component
export default class FileDrop extends Vue {
  @Prop() acceptedFiles!: string;

  files: File[] = [];

  @Watch("files")
  @Emit("filesUpdate")
  private fileChange() {
    return this.files;
  }

  remove(i: number) {
    this.files.splice(i, 1);
  }
  dragOver: boolean = false;

  dragover(event: DragEvent) {
    this.dragOver = true;
  }
  dragleave(event: DragEvent) {
    this.dragOver = false;
  }

  drop(event: DragEvent) {
    this.dragOver = false;
    if (event.dataTransfer) {
      let droppedFiles = event.dataTransfer.files;
      [...droppedFiles].forEach((f) => {
        this.files.push(f);
      });
    }
  }
  private requiredRule(files: File[]): any {
    let retValue: boolean | string = true;

    files.forEach((file) => {
      let acceptedFilesArray = this.acceptedFiles.split(",");
      let find: boolean = false;
      for (let index = 0; index < acceptedFilesArray.length; index++) {
        const element: string = acceptedFilesArray[index];
        if (file.name.toLowerCase().endsWith(element.toLowerCase())) {
          find = true;
        }
      }
      if (!find) {
        retValue = "The files must be of type : " + this.acceptedFiles;
      }
    });

    return retValue;
  }
}
