import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{staticClass:"text-center"},[_c(VCol,{staticClass:"mb-4"},[_c('h1',{staticClass:"display-1 font-weight-bold mb-3"},[_vm._v(" RPP to PSA converter ")])])],1),_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardText,[_c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"lg":"5"}},[_c(VSheet,{staticClass:"pa-6",attrs:{"outlined":"","height":"100%"}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v("Select files from your project drive ")]),_c('file-selection',{attrs:{"acceptedFiles":"rpp","multiple":""},model:{value:(_vm.selectedUserFiles),callback:function ($$v) {_vm.selectedUserFiles=$$v},expression:"selectedUserFiles"}})],1)],1),_c(VCol,{attrs:{"lg":"1","align":"center","justify":"center"}},[_c('span',{staticClass:"font-weight-black text-center text-h5"},[_vm._v(" OR ")])]),_c(VCol,{attrs:{"lg":"5"}},[_c(VSheet,{staticClass:"pa-6",attrs:{"outlined":"","height":"100%"}},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v(" Files from your computer ")]),_c('file-drop',{attrs:{"accepted-files":".rpp"},on:{"filesUpdate":_vm.selectedComputerFilesChange}})],1)],1)],1)],1)],1),_c(VCardActions,[_c(VBtn,{staticClass:"mx-auto",attrs:{"color":"primary","disabled":_vm.filesToConvertCount === 0},on:{"click":_vm.launchConversion}},[_vm._v(" Launch conversion "),(_vm.filesToConvertCount > 0)?_c('span',{staticClass:"ml-1"},[_vm._v(" ("+_vm._s(_vm.filesToConvertCount)+" Fichiers)")]):_vm._e()])],1)],1)],1)],1),_c(VRow,{staticClass:"text-center"},[_c(VCol,{staticClass:"mb-4"},[_c(VCard,{attrs:{"outlined":"","elevation":"2","min-height":200}},[_c(VCardTitle,[_vm._v("Last conversions")]),_c(VCardText,[_c('tasks-list',{attrs:{"tasktype":"RPPtoPSA"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }